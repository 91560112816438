import { Grid, Box } from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import InstallMobileOutlinedIcon from "@mui/icons-material/InstallMobileOutlined";
import styles from "./Instruction.module.scss";

const InstructionAndroid = () => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={styles.container}
        >
            <Grid item>
                <h1 className={styles.header}>MAKE IT AN ANDROID APP!</h1>
                <p className={styles.subHeader}>
                    Get timely reminders for appointments and much more!
                </p>

                <Box
                    display="flex"
                    flexDirection="column"
                    className={styles.box}
                    alignItems="center"
                    justifyContent="center"
                >
                    <img src="/images/mobile.png" alt="mobile_icon" />
                    <p className="font-bold">For Android Users</p>
                    <ol className={styles.steps}>
                        <li>
                            Open this page on <span>Chrome</span>
                        </li>
                        <li className={styles.InstructionWithIcon}>
                            Press <span>More</span> Button in Top Bar{" "}
                            <MoreVertOutlinedIcon className={styles.icon} />
                        </li>
                        <li className={styles.InstructionWithIcon}>
                            Scroll down and click <span>"Install App"</span>{" "}
                            <InstallMobileOutlinedIcon
                                className={styles.icon}
                            />
                        </li>
                        <li>
                            Then open the app from your home screen and login
                        </li>
                    </ol>
                    <a
                        href="https://wa.me/+6580434254?text=Hello%21"
                        className={styles.button}
                    >
                        Not sure how to install? Reach us!
                    </a>
                </Box>

                <p className={styles.footerText}>
                    Please note that our app is by invite only for customers and
                    official partners as we are still in beta.
                </p>
            </Grid>
        </Grid>
    );
};

export default InstructionAndroid;
