import styles from "./Instruction.module.scss";
import { Grid, Box } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import PWAPrompt from "react-ios-pwa-prompt";

const InstructioIos = () => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={styles.container}
        >
            <Grid item>
                <h1 className={styles.header}>MAKE IT AN IOS APP!</h1>
                <p className={styles.subHeader}>
                    Get timely reminders for appointments and much more!
                </p>

                <Box
                    display="flex"
                    flexDirection="column"
                    className={styles.box}
                    alignItems="center"
                    justifyContent="center"
                >
                    <img src="/images/mobile.png" alt="mobile_icon" />
                    <ol className={styles.steps}>
                        <li>
                            Open this page on <span>Chrome</span> or{" "}
                            <span>Safari</span>
                        </li>
                        <li className={styles.InstructionWithIcon}>
                            Press <span>Share</span> Icon in Navigation Bar{" "}
                            <IosShareIcon className={styles.icon} />
                        </li>
                        <li className={styles.InstructionWithIcon}>
                            Scroll down and select{" "}
                            <span>"Add to Home Screen"</span>{" "}
                            <AddBoxOutlinedIcon className={styles.icon} />
                        </li>
                        <li>
                            Then open the app from your home screen and login
                        </li>
                    </ol>
                    <PWAPrompt
                        promptOnVisit={1}
                        timesToShow={3}
                        copyClosePrompt="Close"
                        permanentlyHideOnDismiss={false}
                    />
                    <a
                        href="https://wa.me/+6580434254?text=Hello%21"
                        className={styles.button}
                    >
                        Not sure how to install? Reach us!
                    </a>
                </Box>
                <p className={styles.footerText}>
                    Please note that our app is by invite only for customers and
                    official partners as we are still in beta.
                </p>
            </Grid>
        </Grid>
    );
};

export default InstructioIos;
