import { Grid, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import moment from "moment";

import styles from "./Home.module.scss";
import CardServiceBook from "../../components/Home/CardServiceBook";
import UpcomingAppointmentTable from "../../components/Home/UpcomingAppointmentTable";
import PastAppointmentTable from "../../components/Home/PastAppointmentTable";
import OrderHistoryTable from "../../components/Home/OrderHistoryTable";
import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const HomeOverview = ({ auth, showInfoCards = true }: any) => {
    const { enqueueSnackbar } = useSnackbar();

    const [servicesLoading, setServicesLoading] = useState<boolean>(true);
    const [appointmentData, setAppointmentData] = useState([]);
    const [pastAppointmentData, setPastAppointmentData] = useState([]);
    const [orderHistoryData, setOrderHistoryData] = useState([]);

    const formatServiceType = (service: any) => {
        let type = "";
        if (service["Service"] !== undefined) {
            if (service["Service"] === "AC") {
                type = "Aircon Servicing";
            } else if (service["Service"] === "FL") {
                type = "Floor Servicing";
            }
        }
        return type;
    };

    const formatAppointmentData = (service: any, date: any) => {
        const type = formatServiceType(service);
        const momentDate = moment(date);
        let servicingUnits = 0;

        if (service["Servicing Units"] !== undefined) {
            servicingUnits = parseInt(service["Servicing Units"]);
        }

        return {
            type: type,
            date: momentDate.format("Do MMMM YYYY"),
            time: momentDate.format("h:mm a"),
            units: servicingUnits,
            isPast: !moment().isBefore(momentDate),
        };
    };

    const fetchUserServices = async (servicesBooked: any[]) => {
        if (servicesBooked.length <= 0) {
            setServicesLoading(false);
            enqueueSnackbar(`No services found`, {
                variant: "error",
            });
            return false;
        }
        try {
            const response = await axios.post(
                `${BACKEND_URL}/services/`,
                {
                    servicesBooked: servicesBooked,
                },
                {
                    headers: {
                        Authorization: `Bearer ${auth.firebaseIdToken}`,
                    },
                },
            );
            const { services } = response.data;
            if (!services) {
                return enqueueSnackbar(
                    "Something went wrong, please try again",
                    {
                        variant: "error",
                    },
                );
            }
            const clonedAppointmentData: any = [...appointmentData];
            const clonedPastAppointmentData: any = [...pastAppointmentData];
            const clonedOrderHistoryData: any = [...orderHistoryData];

            services.forEach((userService: any) => {
                const service: any = userService.fields;

                let serviceDate: any = "";
                if (userService._rawJson.createdTime !== undefined) {
                    serviceDate = moment(
                        userService._rawJson.createdTime,
                    ).format("Do MMMM YYYY");
                }

                clonedOrderHistoryData.push({
                    service: formatServiceType(service),
                    date: serviceDate,
                    contractSize: service["Contract Size"] ?? 0,
                });

                if (service["1st Appointment"] !== undefined) {
                    const formattedService = formatAppointmentData(
                        service,
                        service["1st Appointment"],
                    );
                    if (!formattedService.isPast) {
                        clonedAppointmentData.push(formattedService);
                    } else {
                        clonedPastAppointmentData.push(formattedService);
                    }
                }

                if (service["2nd Appointment"] !== undefined) {
                    const formattedService = formatAppointmentData(
                        service,
                        service["2nd Appointment"],
                    );
                    if (!formattedService.isPast) {
                        clonedAppointmentData.push(formattedService);
                    } else {
                        clonedPastAppointmentData.push(formattedService);
                    }
                }

                if (service["3rd Appointment"] !== undefined) {
                    const formattedService = formatAppointmentData(
                        service,
                        service["3rd Appointment"],
                    );
                    if (!formattedService.isPast) {
                        clonedAppointmentData.push(formattedService);
                    } else {
                        clonedPastAppointmentData.push(formattedService);
                    }
                }

                if (service["4th Appointment"] !== undefined) {
                    const formattedService = formatAppointmentData(
                        service,
                        service["4th Appointment"],
                    );
                    if (!formattedService.isPast) {
                        clonedAppointmentData.push(formattedService);
                    } else {
                        clonedPastAppointmentData.push(formattedService);
                    }
                }
            });

            setAppointmentData(clonedAppointmentData.reverse());
            setPastAppointmentData(clonedPastAppointmentData.reverse());
            setOrderHistoryData(clonedOrderHistoryData.reverse());
            setServicesLoading(false);
        } catch (err: any) {
            setServicesLoading(false);
            enqueueSnackbar(err.response.data.error ?? "Something went wrong", {
                variant: "error",
            });
        }
    };

    useEffect(() => {
        fetchUserServices(auth.servicesBooked);
    }, []);

    return (
        <>
            {showInfoCards && (
                <Grid spacing={2} container className={styles.topRow}>
                    <Grid item xs={12} sm={12}>
                        <CardServiceBook auth={auth} />
                    </Grid>
                </Grid>
            )}

            <Box className={styles.tableRow}>
                <UpcomingAppointmentTable
                    loading={servicesLoading}
                    rowData={appointmentData}
                />
            </Box>

            <Box className={styles.tableRow}>
                <PastAppointmentTable
                    loading={servicesLoading}
                    rowData={pastAppointmentData}
                />
            </Box>

            <Box className={styles.tableRow}>
                <OrderHistoryTable
                    loading={servicesLoading}
                    rowData={orderHistoryData}
                />
            </Box>
        </>
    );
};

export default HomeOverview;
