import { Typography, Card, CardContent, Grid, Box } from "@mui/material";

import AuthLayout from "../../layout/AuthLayout";
import AuthHeader from "../../components/AuthHeader";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormInput } from "../../components/Form/FormInput";
import { FormButton } from "../../components/Button/FormButton";
import styles from "./Login.module.scss";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import authStore from "../../store/authStore";
import { MuiTelInput } from "mui-tel-input";
import axios from "axios";
import moment from "moment";
import firebaseAuth from "../../utils/firebase";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Login = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { setAuthData, clearAuthData } = authStore((state: any) => state);

    const [phoneValue, setPhoneValue] = useState<string>("+65");

    const handlePhoneChange = (newValue: string) => {
        setPhoneValue(newValue);
    };
    const [appLoading, setAppLoading] = useState<boolean>(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const [recaptchaVerifier, setRecaptchaVerifier]: any = useState(null);

    useEffect(() => {
        document.title = "Login | Handdi.io";
        const recaptchaVerifier = new RecaptchaVerifier(
            firebaseAuth,
            "recaptcha-container",
            {
                size: "invisible",
            },
        );
        setRecaptchaVerifier(recaptchaVerifier);
        clearAuthData();
    }, []);

    const [confirmationResult, setConfirmationResult]: any = useState(null);

    const findUserData = async (firebaseIdToken: any) => {
        if (!firebaseIdToken) {
            return enqueueSnackbar("Something went wrong, please try again", {
                variant: "error",
            });
        }
        try {
            const response = await axios.get(`${BACKEND_URL}/users/user`, {
                headers: {
                    Authorization: `Bearer ${firebaseIdToken}`,
                },
            });
            const { user } = response.data;
            if (!user) {
                return enqueueSnackbar(
                    "Something went wrong, please try again",
                    {
                        variant: "error",
                    },
                );
            }
            setAuthData({
                ...user,
                firebaseIdToken,
                //it is 3600 seconds, 1 hour
                expiresAt: moment().add(3600, "seconds").unix(),
            });
            navigate("/");
        } catch (err: any) {
            enqueueSnackbar(err.response.data.error, {
                variant: "error",
            });
        }
    };

    const onSubmitPhone = async () => {
        setAppLoading(true);
        const phoneNumber = `+${phoneValue.replace(/\D/g, "")}`;

        signInWithPhoneNumber(firebaseAuth, phoneNumber, recaptchaVerifier)
            .then((confirmationResult) => {
                setAppLoading(false);
                setConfirmationResult(confirmationResult);
            })
            .catch((error) => {
                const errorMsg =
                    error?.message || "Something went wrong, please try again";
                setAppLoading(false);
                enqueueSnackbar(errorMsg, {
                    variant: "error",
                });
            });
    };

    const onSubmitCode = async (data: any) => {
        if (confirmationResult === null) {
            enqueueSnackbar(`Please redo reCAPTCHA`, {
                variant: "error",
            });
            return false;
        }

        setAppLoading(true);
        const code = data.code;
        confirmationResult
            .confirm(code)
            .then(async (result: any) => {
                const firebaseIdToken = result.user.accessToken;
                await findUserData(firebaseIdToken);
                setAppLoading(false);
            })
            .catch((error: any) => {
                console.log({ error });
                setAppLoading(false);
                enqueueSnackbar(`Bad verification code, login again!`, {
                    variant: "error",
                });
            });
    };

    return (
        <AuthLayout>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: "85vh" }}
            >
                <Grid item>
                    <AuthHeader title="Handdi.io" />
                    {confirmationResult === null ? (
                        <form onSubmit={handleSubmit(onSubmitPhone)}>
                            <Card style={{ width: "338px" }}>
                                <CardContent>
                                    <Box
                                        style={{
                                            marginBottom: "12px",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Typography
                                            variant="h3"
                                            className={`font-inter ${styles.title}`}
                                        >
                                            Enter your phone number
                                        </Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            marginBottom: "27px",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            className={`${styles.subtitle}`}
                                        >
                                            Welcome to the Handdi.io Customer
                                            Interface. Please enter your phone
                                            number.
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <MuiTelInput
                                                value={phoneValue}
                                                onChange={handlePhoneChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <FormButton
                                        type="submit"
                                        loading={appLoading}
                                        label="Enter"
                                        theme="custom"
                                        fullWidth={false}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "19px",
                                        }}
                                    />
                                </CardContent>
                            </Card>
                        </form>
                    ) : (
                        <form onSubmit={handleSubmit(onSubmitCode)}>
                            <Card style={{ width: "338px" }}>
                                <CardContent>
                                    <Box
                                        style={{
                                            marginBottom: "12px",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Typography
                                            variant="h3"
                                            className={`font-inter ${styles.title}`}
                                        >
                                            Enter the code sent to your phone
                                            number
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FormInput
                                                name="code"
                                                type="text"
                                                rules={{
                                                    required: "Enter this",
                                                }}
                                                label="Code"
                                                size="small"
                                                control={control}
                                                errors={errors}
                                            />
                                        </Grid>
                                    </Grid>
                                    <FormButton
                                        type="submit"
                                        loading={appLoading}
                                        label="Enter"
                                        theme="custom"
                                        fullWidth={false}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "19px",
                                        }}
                                    />
                                </CardContent>
                            </Card>
                        </form>
                    )}
                </Grid>
            </Grid>

            <div id="recaptcha-container"></div>
        </AuthLayout>
    );
};

export default Login;
